import React, { useCallback } from 'react';
import clsx from 'clsx';
import Centralizer from '../Centralizer/Centralizer';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { ContactInfoContextType } from './context';
import { closeOutline, copyOutline } from 'ionicons/icons';
import s from './ContactInfoModal.module.scss';
import sg from '../../styles/global.module.scss';
import { useTypedDispatch } from '../../redux/hooks';
import { globalActionCreators } from '../../redux/slices/global/actionCreator';
import { ToastPriorityEnum } from '../../utils/enums';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { IS_INSTAGRAM } from '../../utils/browser';
import { WHATS_APP_PHONE_NUMBER } from '../../utils/phoneNumber';
import { TextUsButton } from '../../pages/landing/Components/Sections/Form/Subforms/TextUsButton';

export const ContactInfoModal: React.FC<{
  contactInfoContextData: ContactInfoContextType;
}> = ({ contactInfoContextData }) => {
  const dispatch = useTypedDispatch();

  const info = contactInfoContextData!.info;

  const content = info?.product
    ? `Hello, I am interested in this offering: \n
https://eventmaker.app/u/catalog/${info.product.id} \n
Can we discuss dates availability and pricing?`
    : `Hello, I am interested in offerings from this company: \n
https://eventmaker.app/u/catalog/business/${info?.businessId} \n 
Can we discuss dates availability and pricing?`;

  const link = `https://wa.me/${WHATS_APP_PHONE_NUMBER}?text=${encodeURI(
    content
  )}`;

  const onMessageCopy = useCallback(() => {
    navigator.clipboard.writeText(content || '-');
    dispatch(
      globalActionCreators.setToastObject({
        message: 'Text message copied to clipboard',
        color: 'success',
        priority: ToastPriorityEnum.HIGH,
      })
    );
  }, [dispatch, content]);

  const close = () => contactInfoContextData?.setInfo(undefined);

  return (
    <IonModal
      animated={!IS_INSTAGRAM}
      backdropDismiss
      keepContentsMounted
      handle={false}
      isOpen={!!contactInfoContextData?.info}
      onWillDismiss={close}
      className={clsx(s.IonModal)}
    >
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="end">
            <IonButton onClick={close}>
              <IonIcon icon={closeOutline} className={clsx(s.Close__button)} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div style={{ padding: '10px' }} className={clsx(sg.Stack_Vertical)}>
        <p className={clsx(s.Text__Content)} style={{ padding: '10px 0' }}>
          Please send us this message to Whats App:
        </p>

        <Centralizer>
          <div
            className={clsx(
              sg.Stack_Horizontal,
              sg.Justify_Center,
              sg.Gap_10,
              s.Text__dotted_border
            )}
            style={{ padding: '10px', marginTop: '10px' }}
          >
            <p className={clsx(s.Text__Content)}>{content}</p>

            <ButtonMicroWrapper onClick={onMessageCopy}>
              <IonIcon src={copyOutline} className={clsx(s.IonIcon__copy)} />
            </ButtonMicroWrapper>
          </div>
        </Centralizer>

        <Centralizer>
          <div>
            <TextUsButton
              alignToCenter
              onClick={() => {
                close();
                window.open(link);
              }}
            />
          </div>
        </Centralizer>
      </div>
    </IonModal>
  );
};
