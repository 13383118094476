import React from 'react';
import s from './EventTypes.module.scss';
import { ActionButton } from '../../ActionButton/ActionButton';
import { eventTypes } from './config';
import { useLandingPageContext } from '../../../context';
import Centralizer from '../../../../../components/Centralizer/Centralizer';

export const EventTypesMobile = () => {
  const { source } = useLandingPageContext();
  if (source !== 'customer') return null;

  return (
    <section>
      <div className={s.section}>
        <h2 data-animation>
          Book services from <span>various vendors</span> to create your own
          event
        </h2>
        <div data-animation className={s.animation}>
          <div className={s.slider}>
            {eventTypes.map((slide, index) => (
              <div key={index} className={s.slide}>
                {/* <p className={s.slideTitle}>{slide.text}</p> */}

                <div
                  className={s.slideImageContainer}
                  style={{ backgroundImage: `url(${slide.src})` }}
                >
                  <Centralizer enableVerticalCentralization>
                    <p
                      className={s.slideOverlappedText}
                      data-text={slide.text.toUpperCase()}
                    >
                      {slide.text.toUpperCase()}
                    </p>
                  </Centralizer>
                </div>

                {/* <span> */}
                {/* <ul style={{ background: 'green' }}>
                  <li>
                    <p>A</p>
                  </li>
                  <li>
                    <p>A</p>
                  </li>
                  <li>
                    <p>A</p>
                  </li>
                </ul> */}
                {/* </span> */}
              </div>
            ))}
          </div>

          <span>
            <ActionButton />
          </span>
        </div>
      </div>
    </section>
  );
};
