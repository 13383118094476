import { getStorageUrl } from '../../../Utils/getStorageUrl';

export const eventTypes = [
  {
    text: 'Party',
    src: getStorageUrl('landing/event_examples/party.webp'),
  },
  {
    text: 'Wedding',
    src: getStorageUrl('landing/event_examples/wedding.webp'),
  },
  {
    text: 'Birthday',
    src: getStorageUrl('landing/event_examples/birthday.webp'),
  },
  {
    text: 'Festival',
    src: getStorageUrl('landing/event_examples/festival.webp'),
  },
  {
    text: 'Children Party',
    src: getStorageUrl('landing/event_examples/kids.webp'),
  },
  {
    text: 'Photoshoot',
    src: getStorageUrl('landing/event_examples/photo.webp'),
  },
  {
    text: 'Business Event',
    src: getStorageUrl('landing/event_examples/business.webp'),
  },
  {
    text: 'Concert',
    src: getStorageUrl('landing/event_examples/concert.webp'),
  },
  {
    text: 'Art Exhibition',
    src: getStorageUrl('landing/event_examples/exhibition.webp'),
  },
  {
    text: 'Yoga or Dance',
    src: getStorageUrl('landing/event_examples/yoga.webp'),
  },
  {
    text: 'Music Session',
    src: getStorageUrl('landing/event_examples/music.webp'),
  },
  {
    text: 'Other',
    src: getStorageUrl('landing/event_examples/other.webp'),
  },
];
