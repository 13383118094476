import React from 'react';
import s from './Benefits.module.scss';
import { useLandingPageContext } from '../../../context';

export const Benefits = () => {
  const { source } = useLandingPageContext();
  if (source !== 'customer') return null;

  return (
    <section className={s.section}>
      <div className={s.container}>
        <h3 data-animation className={s.section__title}>
          You can <span>use Eventmaker</span> to:
        </h3>
        <div className={s.benefits}>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>1) Book services</h4>
            <p className={s.benefit__body}>
              Our catalog offers many options from different businesses. Easily
              book them through Eventmaker and ensure that you receive{' '}
              <span>event services</span> at the best possible price.
            </p>

            {/* TODO: How do we guarantee best pricing? / How we keep quality of services high?  */}
          </div>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>2) Request event</h4>
            <p className={s.benefit__body}>
              If you prefer industry experts to plan your event, simply inform
              us about <span>your requirements</span>. Our experienced team will
              take care of everything from beginning till the end!
            </p>
            {/* TODO: Our experience / Leave your request */}
          </div>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>3) Find inspiration</h4>
            <p className={s.benefit__body}>
              Scroll our catalog to explore possibilities. Even if you are not
              planning any event right now - you can find{' '}
              <span>extraordinary offerings</span> and save them for future.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
